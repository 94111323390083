import { useEffect, useState } from "react";
import { useActiveTenantStore } from "store/activeTenantStore";
import { useManageActiveTenant } from "hooks/useManageActiveTenant";
import { Outlet } from "react-router-dom";
import { Container, Main } from "./Layout.styled";
import { getUserInfo } from "services/api/config";
import { changeLanguage } from "i18next";
import { useQuery } from "@tanstack/react-query";
import { WithLoggedInAuthInfoProps, withAuthInfo } from "services/auth";
import Sidebar from "../Sidebar";

const Layout = ({ accessToken, orgHelper, user }: WithLoggedInAuthInfoProps) => {
  const [collapsed, setCollapsed] = useState(true);
  const { activeTenant } = useActiveTenantStore();
  useManageActiveTenant(user, orgHelper);

  const { data: userInfo } = useQuery({
    queryFn: () => getUserInfo(accessToken),
    queryKey: ["userInfo", accessToken],
    enabled: !!activeTenant,
  });

  useEffect(() => {
    const langMap: { [key: string]: string } = { english: "en", spanish: "es" };
    if (userInfo?.language) {
      changeLanguage(langMap[userInfo.language]);
    }
  }, [userInfo]);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  // TODO: Add a loading spinner
  if (!activeTenant) return null;

  return (
    <Container data-testid="layout">
      <Sidebar collapsed={collapsed} handleCollapse={handleCollapse} />
      <Main collapsed={collapsed}>
        <Outlet />
      </Main>
    </Container>
  );
};

export default withAuthInfo(Layout);
