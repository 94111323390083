import { useQuery } from "@tanstack/react-query";
import { WithLoggedInAuthInfoProps, withAuthInfo } from "@propelauth/react";
import { isTokenExpired } from "utils/jwt";
import { getMetabaseToken } from "services/api/analytics";

const fetchToken = async (accessToken: string, dashboardId: number) => {
  const token = await getMetabaseToken(accessToken, dashboardId);
  localStorage.setItem(dashboardId.toString(), token);
  return token;
};

type MetabaseDashboardProps = WithLoggedInAuthInfoProps & {
  dashboardId: number;
};

const MetabaseDashboard = withAuthInfo(({ accessToken, dashboardId }: MetabaseDashboardProps) => {
  const getToken = async () => {
    let token = localStorage.getItem(dashboardId.toString());
    if (!token || isTokenExpired(token)) {
      token = await fetchToken(accessToken, dashboardId);
    }
    return token;
  };

  const { data: metabaseToken } = useQuery({
    queryFn: getToken,
    queryKey: ["metabaseToken", dashboardId],
  });

  const url = metabaseToken
    ? `https://auba.metabaseapp.com/embed/dashboard/${metabaseToken}#bordered=false&titled=false`
    : "";

  return <iframe src={url} title="Metabase Dashboard" />;
});

export default MetabaseDashboard;
