import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import config from "config";
import * as amplitude from "@amplitude/analytics-browser";
import { Navigate, Route, Routes } from "react-router-dom";
import { withAuthInfo } from "services/auth";
import "assets/stylesheets/App.css";
import AlgoliaSearchWrapper from "features/Tracking/components/wrappers/AlgoliaSearchWrapper";
import LandingPage from "pages/LandingPage";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Tracking from "pages/Tracking";
import RailTracking from "pages/RailTracking";
import EmailIngestion from "pages/EmailIngestion";
import ContainerView from "pages/ContainerView";
import Layout from "components/layouts/Layout";
import paths from "constants/paths";
import CompleteEmailIngestion from "pages/CompleteEmailIngestion";
import Insights from "pages/Insights";
import LogoutRedirect from "pages/LogoutRedirect";
import { getTenantInfo } from "services/api/config";

const { RAIL_TENANT_ID } = config.envs;
const { SHOW_CONTAINER_VIEW } = config.ffs;
const REGISTER_RAIL_EVENTS_ROUTE = false;

const App = withAuthInfo(({ accessToken, isLoggedIn, user }) => {
  useEffect(() => {
    if (
      config.envs.AMPLITUDE_DISABLE_FOR_INTERNAL_USERS === "true" &&
      user &&
      /auba\.ai$/.test(user?.email)
    ) {
      // eslint-disable-next-line no-console
      console.log("[amplitude]", "Disabling for internal user");
      return;
    }

    config.envs.AMPLITUDE_API_KEY &&
      amplitude.init(config.envs.AMPLITUDE_API_KEY, {
        userId: user?.userId || "",
        defaultTracking: true,
      });
  }, [user]);

  let registerRailEventsRoute = REGISTER_RAIL_EVENTS_ROUTE;
  if (accessToken) {
    const { data: tenantInfo } = useQuery(
      ["tenantInfo", accessToken],
      () => getTenantInfo(accessToken),
      {
        enabled: true,
      },
    );
    if (tenantInfo?.id === RAIL_TENANT_ID) {
      registerRailEventsRoute = true;
    }
  }

  return (
    <Routes>
      {isLoggedIn ? (
        <>
          <Route path={paths.privacyPolicy} element={<PrivacyPolicy />} />
          <Route path={paths.tracking} element={<Layout />}>
            <Route
              index
              element={
                <AlgoliaSearchWrapper>
                  <Tracking />
                </AlgoliaSearchWrapper>
              }
            />
            <Route
              path={paths.emailIngestion}
              element={<EmailIngestion choices={[{ name: "Outlook" }, { name: "Gmail" }]} />}
            />
            <Route
              path="/ingestion/complete-authorization/:provider"
              element={<CompleteEmailIngestion />}
            />
            <Route path={paths.insights} element={<Insights />} />
            {registerRailEventsRoute && (
              <Route path={paths.railTracking} element={<RailTracking />} />
            )}
            {SHOW_CONTAINER_VIEW && (
              <Route
                path={paths.container}
                element={
                  <AlgoliaSearchWrapper>
                    <ContainerView />
                  </AlgoliaSearchWrapper>
                }
              />
            )}
            {/* ADD HERE MORE PROTECTED ROUTES */}
          </Route>
          {/* TODO: handle this more gracefully? */}
          <Route path="*" element={<Navigate to={paths.tracking} />} />
        </>
      ) : (
        <>
          <Route path={paths.privacyPolicy} element={<PrivacyPolicy />} />
          {/* We have configured Propel Auth to redirect users here after logout */}
          <Route path="/logout-redirect" element={<LogoutRedirect />} />
          <Route path="*" element={<LandingPage />} />
        </>
      )}
    </Routes>
  );
});

export default App;
