import { Container, Table } from "./TrackingTable.styled";
import TableHeader from "../TableHeader";
import SectionTable from "../SectionTable";
import { useLegStore } from "store/trackingStore";
import { TrackingRecord } from "types/trackingTypes";
import AlgoliaInfiniteHits from "./components/AlgoliaInfiniteHits";
import TripCardModal from "features/TripCardModal";
import config from "config";

const { USE_BACKEND_RECORDS } = config.envs;

type TrackingTableProps = {
  trackingRecords: TrackingRecord[];
};

const TrackingTable = ({ trackingRecords }: TrackingTableProps) => {
  const { legId } = useLegStore();

  return (
    <>
      <Container disabled={!!legId}>
        <Table>
          <TableHeader />
          <SectionTable records={trackingRecords} />
        </Table>
      </Container>
      {USE_BACKEND_RECORDS !== "true" && <AlgoliaInfiniteHits />}
      <TripCardModal />
    </>
  );
};

export default TrackingTable;
