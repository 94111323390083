/*
    FIXME @piercebb 

    UploadBlButton component was temporarily removed for the Elektra roll out
    https://github.com/auba-ai/suppl-ai/pull/1335

    Uncomment functionality when BL upload is fixed
    https://linear.app/auba/issue/FEA-70/manual-upload-is-not-functioning

*/
import { useRef } from "react";
import { useTranslation } from "react-i18next";
// import { useMutation } from "@tanstack/react-query";
// import { withAuthInfo, WithLoggedInAuthInfoProps } from "services/auth";
// import { uploadBLFiles } from "services/api/tracking";
// import UploadIcon from "assets/icons/upload_icon.svg";

// interface UploadBlButtonProps extends WithLoggedInAuthInfoProps {
//     setOpenLoadingIndicator?: (value: boolean) => void;
// }

// const UploadBlButton = ({ accessToken, setOpenLoadingIndicator }: UploadBlButtonProps) => {
const UploadBlButton = () => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  // const { mutateAsync: uploadBLFile } = useMutation({
  //     mutationFn: () => uploadBLFiles(accessToken, hiddenFileInput.current!.files!),
  // });

  const handleClickUploadFile = () => {
    hiddenFileInput.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      //setOpenLoadingIndicator(true);
      //uploadBLFile();
    }
  };

  return (
    <>
      <button type="button" onClick={handleClickUploadFile}>
        {/* <img src={UploadIcon} alt="Upload" /> */}
        <span>{t("Upload")} BL</span>
      </button>
      <input
        type="file"
        id="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
        ref={hiddenFileInput}
        accept=".pdf"
        multiple
      />
    </>
  );
};

// export default withAuthInfo(UploadBlButton);
export default UploadBlButton;
