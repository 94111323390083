import { useEffect } from "react";
import { useSearchBox } from "react-instantsearch";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useInfiniteHits, useInstantSearch, useToggleRefinement } from "react-instantsearch";
import { withAuthInfo, WithLoggedInAuthInfoProps } from "@propelauth/react";
import config from "config";
import ActionButtons from "features/Tracking/components/ActionButtons";
import CurrentFilters from "features/Tracking/components/CurrentFilters/CurrentFilters";
import TrackingTable from "features/Tracking/components/TrackingTable";
import AlgoliaConfigure from "features/Tracking/components/AlgoliaConfigure/AlgoliaConfigure";
import Search from "features/Tracking/components/Search";
import FilterDropdown from "features/Tracking/components/FilterDropdown";
import ArchivedButton from "features/Tracking/components/AlgoliaArchivedButton";
import UploadBlButton from "features/Tracking/components/UploadBlButton";
import ExcelExportButton from "features/Tracking/components/ExcelExportButton.tsx";
import { TripHitProps } from "types/trackingTypes";
import { sanitizeAlgoliaTrips } from "utils/sanitizeAlgoliaTrips";
import { upsertFilter } from "services/api/filters";
import { getTrackingRecords } from "services/api/tracking";

const { USE_BACKEND_RECORDS } = config.envs;

const AlgoliaTrackingTable = () => {
  const { hits } = useInfiniteHits<TripHitProps>();
  const tripsRecords = sanitizeAlgoliaTrips(hits);

  return <TrackingTable trackingRecords={tripsRecords} />;
};

const BackendTrackingTable = withAuthInfo(({ accessToken }: WithLoggedInAuthInfoProps) => {
  const {
    value: { isRefined: isArchived },
  } = useToggleRefinement({ attribute: "archived" });

  const { data: activeRecords = [] } = useQuery({
    queryFn: () => getTrackingRecords(accessToken),
    queryKey: ["trackingRecords"],
    enabled: !isArchived,
  });

  const { data: archivedRecords = [] } = useQuery({
    queryFn: () => getTrackingRecords(accessToken, true),
    queryKey: ["archivedRecords"],
    enabled: isArchived,
  });

  return <TrackingTable trackingRecords={isArchived ? archivedRecords : activeRecords} />;
});

const Tracking = withAuthInfo(({ accessToken }: WithLoggedInAuthInfoProps) => {
  const { t } = useTranslation();
  const { uiState } = useInstantSearch();
  const { query: searchValue } = useSearchBox();

  useDocumentTitle(t("Tracking"));

  const { mutate: saveUIState } = useMutation({
    mutationFn: () => upsertFilter(accessToken, JSON.stringify(uiState)),
  });

  useEffect(() => {
    saveUIState();
  }, [uiState, saveUIState]);

  return (
    <>
      <AlgoliaConfigure />
      <ActionButtons title="Tracking">
        <Search />
        <FilterDropdown />
        <ArchivedButton />
        <UploadBlButton />
        <ExcelExportButton searchValue={searchValue} />
      </ActionButtons>
      <CurrentFilters />
      {USE_BACKEND_RECORDS === "true" ? <BackendTrackingTable /> : <AlgoliaTrackingTable />}
    </>
  );
});

export default Tracking;
