// Paths for each page in the app, used for routing and navigation purposes
const paths = {
  tracking: "/",
  landingPage: "/",
  emailIngestion: "/email-ingestion",
  // this one is configured in the oauth apps for
  // microsoft and google, so it can't be updated
  // without changes there (in their admin panels)
  completeAuthorization: "/ingestion/complete-authorization/:provider",
  privacyPolicy: "/privacy-policy",
  insights: "/insights",
  railTracking: "/rail-tracking",
  container: "/container",
};

export default paths;
