import { useTranslation } from "react-i18next";
import { useSearchBox } from "react-instantsearch";

import { useDocumentTitle } from "hooks/useDocumentTitle";
import ActionButtons from "features/Tracking/components/ActionButtons";
import AlgoliaConfigure from "features/Tracking/components/AlgoliaConfigure/AlgoliaConfigure";
import ArchivedButton from "features/Tracking/components/AlgoliaArchivedButton";
import Search from "features/Tracking/components/Search";
import FilterDropdown from "features/Tracking/components/FilterDropdown";
import ExcelExportButton from "features/Tracking/components/ExcelExportButton.tsx";

const ContainerView = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("Container"));

  const { query: searchValue } = useSearchBox();

  return (
    <>
      <AlgoliaConfigure />
      <ActionButtons title="Container">
        <Search />
        <FilterDropdown />
        <ArchivedButton />
        <ExcelExportButton searchValue={searchValue} />
      </ActionButtons>
    </>
  );
};

export default ContainerView;
