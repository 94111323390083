import { useEffect, useState } from "react";
import { downloadFile } from "services/api/files";

export const useFileURL = (fileId: string, accessToken: string) => {
  const [fileURL, setFileURL] = useState("");

  useEffect(() => {
    if (!fileId) return;

    let url: string | undefined = undefined;
    const fetchFile = async () => {
      const data = await downloadFile(accessToken, fileId);
      url = URL.createObjectURL(new Blob([data], { type: "application/pdf" }));
      setFileURL(url);
    };
    fetchFile();

    return () => {
      if (url !== undefined) {
        URL.revokeObjectURL(url);
      }
    };
  }, [accessToken, fileId]);

  return [fileURL, setFileURL] as const;
};
